import { template as template_fbbc3696f8f0428ab8e8678b747b7c5e } from "@ember/template-compiler";
const FKLabel = template_fbbc3696f8f0428ab8e8678b747b7c5e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
