import { template as template_ff645c39ded74609a681dff813964880 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ff645c39ded74609a681dff813964880(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
