import { template as template_7bee193d47ff40a9bfe9a16ae514436b } from "@ember/template-compiler";
const WelcomeHeader = template_7bee193d47ff40a9bfe9a16ae514436b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
